import {
  init,
  breadcrumbsIntegration,
  browserTracingIntegration
} from '@sentry/react';
import { isProduction } from './env';

const initSentry = () => {
  if (isProduction) {
    init({
      dsn: 'https://c1b6144ff1e64700ab8f91112aa470a7@sentry.io/1834548',
      environment: process.env.ENV,
      beforeSend: (event, hint) => {
        event.contexts = {
          ...event.contexts,
          error: { originalMessage: hint?.originalException }
        };
        return event;
      },
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (!!hint) {
          // ref: https://docs.sentry.io/platforms/javascript/configuration/filtering/#hints-for-breadcrumbs
          if (breadcrumb.data && hint.input && hint.input[1]?.body) {
            // when breadcrumb is created from HTTP requests by fetch API, it captures request query.
            // hint.input is tuple, ["request url", "request body"]
            breadcrumb.data.requestQuery = JSON.stringify(hint.input[1].body);
          }
        }
        return breadcrumb;
      },
      integrations: [
        browserTracingIntegration(),
        breadcrumbsIntegration({
          dom: {
            serializeAttribute: 'data-cy'
          }
        })
      ],
      maxValueLength: 1000,
      normalizeDepth: 10,
      release: process.env.IMAGE_TAG,
      allowUrls: [
        /https:\/\/((g4b-stg|g4b)\.)?giftee\.biz/,
        /https:\/\/d117475980nbgp\.cloudfront\.net/
      ],
      tracesSampleRate: 0.2
    });
  } else {
    console.log('Sentry does not initialized in development mode...');
  }
};

export default initSentry;
